/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'node_modules/ngx-toastr/toastr';

//@import "~bootstrap/scss/bootstrap-grid.scss";

html, body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: medium;
  color: #4b4b4d;
  font-weight: 400;
  line-height: normal;
}

div {
  margin: 0;
  padding: 0;
}

a {
color: inherit;
}

a:hover, a:focus {
color: inherit;
}

.header-bg {
 padding: 0;
 margin: 0;
}

/*size*/
.s11 {
    font-size: 11px;
    line-height: normal;
  }

  .s15 {
    font-size: 15px;
    line-height: normal;
  }

  .s16 {
    font-size: 16px;
    line-height: normal;
  }

  .s20 {
    font-size: 20px;
    line-height: normal;
  }

  .s28 {
    font-size: 28px;
    line-height: normal;
  }

  .s35 {
    font-size: 35px;
    line-height: normal;
  }

  .s40 {
    font-size: 40px;
    line-height: normal;
  }

  .s50 {
    font-size: 50px;
    line-height: normal;
  }

  .title1 {
    color: #203b57;
    font-size: 28.59px;
    font-weight: bold;
    line-height: 32.295px;
    text-transform: uppercase;
    padding: 2vw 0px 1vw 0px;
    text-align: center;
    margin-bottom:20px;
    scroll-margin-top: 52px;
  }

  .title2 {
    padding-bottom: 0.5vw;
    font-weight: bold;
    text-transform: uppercase;
  }

 .sub_title1 {
    color: #203b57;
    font-size: 20px;
    font-weight: 500;
    line-height: 32.295px;
    text-transform: uppercase;
    padding: 60px 0px 0px 0px;
    text-align: center;
}

.title1_separator {
    padding: 0px 18px;
    color: #b6b5b5;
}

.cat_title {
    font-size: 17.87px;
    font-weight: 700;
    line-height: 22.295px;
    color: #203b57;
    text-transform: uppercase;
  }
.cat_title_tiret {
    color : #71405f;
  }

.cat_des {
    color: #4b4b4d;
    font-size: 13.4px;
    line-height: 17.869px;
    font-weight: 400;
  }

.top-container {
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.md-lgn-headline{
    font-size: 16px;
    padding-top: 30px;
    padding-right: 10px;
    font-weight: 600;
}

.top-15 {
    padding-top: 15px;
}

.btn-secondary {
    background-color: #ffd740;
    color: #000000;
}

.btn-success {
    background-color: #673ab7;
    color: #fff;
}

.btn-info {
    background-color: #b0bec5;
    color: #000000;
}

.mat-spinner {
    position: relative;
    margin-left: 50%;
    margin-right: 50%;
}

.mat-form-field {
    display: block;
    width: 70%;
}

.mat-radio-button ~ .mat-radio-button {
    margin-left: 16px;
  }

/* Pre-built theme : indigo-pink */
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:#ff4081;
}

.divider {
  border: 0.5px solid white;
}

.not-available .mat-radio-outer-circle, .pink .mat-radio-outer-circle,
.blue .mat-radio-outer-circle, .pink .mat-radio-outer-circle,
.red .mat-radio-outer-circle, .purple .mat-radio-outer-circle {
  border-color:white;
}

.not-available .mat-radio-checked /*.mat-radio-inner-circle*/,
.red .mat-radio-checked /*.mat-radio-inner-circle*/,
.blue .mat-radio-checked /*.mat-radio-inner-circle*/,
.purple .mat-radio-checked /*.mat-radio-inner-circle*/,
.pink .mat-radio-checked /*.mat-radio-inner-circle*/ {
  background-color: white;
}

.box {
  height: 100%;
}

[class*='col-md'] > .box {
  margin-top: 1em;
}

.grey_bg {
  background-color: #dadada;
}

.gold_bg {
  background: #998d7c;
}

.red_bg {
  background: #9a0c0a;
}

.blue_bg {
  background: #384454;
}

.piliers {
  padding: 1.1em;
}
.piliers_title {
  color: #ffffff;
  font-size: 17.87px;
  font-weight: 700;
  line-height: 22.295px;
  text-transform: uppercase;
}

.title-blue{
	color:#203b57;
}

.title-red{
	color:#e30613;
}

.marine_txt {
  color: #203b57;
}

.infos_btn {
  color: #ffffff;
  font-size: 13.4px;
  line-height: 17.869px;
  font-weight: 700;
  padding: 5px 25px;
  //margin: 0;
  border: 1px solid #203b57;
  background-color: #203b57;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1);
  transition: all 200ms ease-in;
}

.infos_btn:hover, .infos_btn:focus {
  border: 1px solid #203b57;
  background: #fff;
  color: #203b57;
  text-decoration: none;
}

.produits_p {
  padding-top: 35px;
  padding-bottom: 20px;
  background: #ececec;
}
.produits_p:nth-child(2n+1) {
  padding-top: 35px;
  background: #ffffff;
}
.bg_gris {
  background: #ececec;
}
.title2 {
  font-size: 17.87px;
  font-weight: 700;
  line-height: 22.295px;
  color: #203b57;
text-transform: uppercase;
}
.title2_tiret {
  color : #71405f;
}
.intro-text {
  color: #4b4b4d;
  //font-size: 20px;
  font-weight: 400;
  margin-bottom: 1.5vw;
}

.violet-hr {
  border-top: 1px solid #203b57;
  margin: 37px 40%;
  align-self: center;
}

.violet-hr2 {
  border-top: 1px solid #203b57;
}
.produit {
  margin: 0.5em;
  max-width: calc(100% * (1/4) - 2.1vw);
}

.produit_item {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.produit_item:hover {
  -webkit-text-fill-color: #203b57;
  color: #203b57;
}

.produit_item img {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1.0;
}
.produit_item:hover img {
  -webkit-transform: scale3d(1.03, 1.03, 1);
  transform: scale3d(1.03, 1.03, 1);
  opacity: 0.5;
}
.produit_title {
  color: #203b57;
  font-size: 16.1px;
  font-weight: 700;
  line-height: 17.295px;
  text-align: center;
  padding: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  border-bottom: 10px solid #b4a89a;
}

.photo_title {
  color: #203b57;
  font-size: 16.1px;
  text-align: center;
  padding: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
}

.produit:nth-child(2n+1) .produit_item .produit_title {
  border-bottom: 10px solid #b4a89a;
}

.produit_btn {
color: #203b57;
font-size: 12.56px;
font-weight: 400;
line-height: 17.295px;
  padding: 13px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.produit_btn:hover, .produit_btn:focus {
opacity: 0.9;
}

.produit_btn img {
  padding-top: 5px;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.justify {
  text-align: justify;
}

.chiffre{
	font-weight: bold;
	font-size: 12vw;
}

.lib_chiffre{
	text-align:center;
}

.kpi_block{
  text-align:center;
}

.suggestions {
	margin-bottom: 48px;
	background: #ececec;
}

.suggestions_b_r {
    height: auto;
    padding: 28px;
    color:#4b4b4d;
}

.suggestions_title  {
    color: #203b57;
    font-size: 16.1px;
    font-weight: 700;
    line-height: 17.295px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
}

.suggestions_title span  {
    color: #b0afaf;
}

.marine_bg {
  background: #203b57;
}

.banner, .banner_img {
  padding: 0;
}

.banner_title {
  font-size: 22.31px;
  font-weight: 500;
  line-height: 18.129px;
  padding-top: 35px;
  text-transform: uppercase;
}

.banner_txt {
  padding: 25px 0;
  line-height: 1.5em;
}

.white_txt{
	color:#fff;
}

.dark_txt{
	color:#4b4b4d;
}

.white_btn {
  color: #203b57;
  font-size: 13.4px;
  line-height: 17.869px;
  font-weight: 700;
  padding: 5px 25px;
  margin: 0;
  border: 1px solid #fff;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1);
  transition: all 200ms ease-in;
}

.white_btn:hover, .white_btn:focus {
  border: 1px solid #fff;
  background: #203b57;
  color: #fff;
  text-decoration: none;
}

.red_btn {

  color: #fff;
  font-size: 13.4px;
  line-height: 17.869px;
  font-weight: 700;
  padding: 5px 25px;
  margin: 0;
  border: 1px solid #e30613;
  background-color: #e30613;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1);
  transition: all 200ms ease-in;
}

.red_btn:hover, .red_btn:focus {
  border: 1px solid #e30613;
  background: #fff;
  color: #e30613;
  text-decoration: none;
}

.section {
  padding: 60px 0px 0px 0px;
}

mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner{
  padding-left: 30px;
}
.text-center {
  text-align:center;
}

.btn-more.btn-blue {
  background-color: #203b57;
}

.btn-more.btn-red {
  background-color:   #e30613;
}

.btn-more.btn-grey {
  background-color:   #4b4b4d;
}

.btn-more {
  margin: 15px;
  padding: 15px;
  color: #ffff;
  font-size: 13.4px;
  line-height: 17.869px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  width: fit-content;
}

.btn-more > a:link {
  text-decoration: none;
}

.btn-more > a:visited {
  text-decoration: none;
}

.btn-more > a:hover {
  text-decoration: underline;
}

.btn-more > a:active {
  text-decoration: underline;
}

.btn-more:hover, .btn-more:focus {
  border: 1px solid #203b57;
  background: #fff;
  color: #203b57;
  text-decoration: none;
  cursor: pointer;
}


.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
}

.tag {
  background: #203b57;
  border-radius: 3px;
  color: white;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

@media screen and (max-width: 575px) {
  .produit {
    max-width: calc(100% * (1/2) - 5vw);
  }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Lien pour les directions */
.btn-primary {
  background-color: #203b57;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #ff0000;
}

.form-step {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nav-button {
  margin: 0 10px;
  background-color: #192A56;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.nav-button:disabled {
  background-color: #BDC3C7;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #C0392B;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #192A56;
}

.mat-input-element {
  color: #192A56;
}
